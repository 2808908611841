<template>
  <v-card flat>
    <v-card-text>
      <apex-chart
        :key="chartKey"
        :height="height - 400"
        :options="stackedColumnChartOptions100"
        :series="seriesData"
        type="bar"
      ></apex-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import {
    updateSeriesWithColors
} from '@/util/functions'
import { ref } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { apiService } from '@/api'
import {
    stackedColumnChartOptions100
} from '@/assets/chartOptions/market'

export default {
    name: 'StackedTimeSeriesMonthly',
    props: {
        series: {
            type: String,
            default: 'chart5',
        },
    },
    setup() {
        const chartKey = ref(0)
        const seriesData = ref([])
        const { width, height } = useWindowSize()
        const data = ref([])

        return {
            updateSeriesWithColors,
            seriesData,
            chartKey,
            height,
            width,
            stackedColumnChartOptions100,
            data,
        }
    },
    mounted() {
        this.getGenDashData()
    },
    methods: {
        async getGenDashData() {
            const response = await apiService.getData(this.series)
            this.data = response.data
            this.seriesData = updateSeriesWithColors(this.data)
            this.stackedColumnChartOptions100 = {
                ...this.stackedColumnChartOptions100,
                xaxis: {
                    categories: [...new Set(response.xValues)],
                },
            }

            this.chartKey += 1
        },
    },
}
</script>
